import { Suspense, useState, lazy } from 'react';
import { Types } from 'src/types';
import { useLocationsStore } from 'src/stores/locationStore';

const SlTextarea = lazy(() => import("@shoelace-style/shoelace/dist/react/textarea"));

interface DescriptionFieldProps {
    location: Types.Location;
}

export default function DescriptionField({ location }: DescriptionFieldProps) {
    const { upsertLocation } = useLocationsStore((state) => state);
    const [inEdit, setInEdit] = useState(false);
    const [text, setText] = useState(location.description || '');

    const handleEdit = () => {
        if (inEdit) upsertLocation({ ...location , description: text });
        setInEdit(!inEdit);
    }

    return (
        <>
            <div className="col-span-2">
                { !inEdit 
                    ? <div className="font-light text-sm text-gray-700 px-3 py-1">
                        { text || <span className="text-gray-400 font-light">Add a description</span> }
                    </div>
                    : <div className="ml-2">
                        <Suspense fallback={<div>Loading...</div>}>
                            <SlTextarea 
                                className="w-full"
                                name="description"
                                placeholder="Describe this location" 
                                value={ text }
                                onSlChange={(e) => setText(e.target.value)}
                            />
                        </Suspense>
                    </div>
                }
            </div>

            <div className="py-1">
                <button 
                    className="text-xs text-blue-500 hover:text-blue-700 text-right"
                    onClick={ handleEdit }
                >
                    { !inEdit ? 'Edit' : 'Save' }
                </button>
            </div>
        </>
    )
}