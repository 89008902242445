import { create } from 'zustand';
import { Types } from '../types';
import { supabase } from 'src/utils/supabaseClient';

const setAttrsToInactive = async (location_id: number, attributes: Types.Attribute[]) => {
    const names = attributes.map((attr) => attr.name);

    // find any active attributes for this location where name in names and set to inactive
    const { error } = await supabase
        .from('attributes')
        .update({ active: false })
        .eq('location_id', location_id)
        .in('name', names);

    if (error) {
        console.error('Error updating attributes', error);
    }
}

const saveAttributes = async (location_id: number, attributes: Types.Attribute[]) => {
    const { error } = await supabase
        .from('attributes')
        .insert(attributes.map((attr) => ({
            location_id: location_id,
            name: attr.name,
            value: attr.value,
            estimated: !isNaN(attr.value) ? attr.estimated : null,
        })));

    if (error) {
        console.error('Error saving attributes', error);
    }
}

interface AttributeState {
    initialValues: Types.Attribute[];
    setInitialValues: (attributes: Types.Attribute[]) => void;

    updatedAttributes: Types.Attribute[];
    setUpdatedAttributes: (attributes: Types.Attribute[]) => void;
    saveAttributes: (attributes: Types.Attribute[]) => void;
} 

export const useAttributeStore = create<AttributeState>((set) => ({
    initialValues: [] as Types.Attribute[],

    setInitialValues: (attributes) => {
        set({ initialValues: attributes });
    },

    updatedAttributes: [] as Types.Attribute[],
    setUpdatedAttributes: (attributes) => {
        set({ updatedAttributes: attributes });
    },
    saveAttributes: async (location_id) => {
        const store = useAttributeStore.getState();
        const attributes = store.updatedAttributes;

        await setAttrsToInactive(location_id, attributes);
        await saveAttributes(location_id, attributes);

        // reset the updatedAttributes now that they have been saved
        store.updatedAttributes = [];

        return attributes;
    }   
}));