import { useEffect, useState, lazy, Suspense } from 'react';
import { Types } from 'src/types';
import AuditEntry from './AuditEntry';
import { useAuditStore } from 'src/stores/auditStore';
import { SlIcon, SlDialog, SlTooltip } from '@shoelace-style/shoelace/dist/react';

const AuditEntryForm = lazy(() => import('./AuditEntryForm'));

interface AuditLogProps {
    location: Types.Location;
}
function handleRequestClose(event) {
    if (event.detail.source === 'overlay') event.preventDefault();
}

export default function AuditLog({ location}: AuditLogProps) {
    const { auditLog, fetchAuditLog, loading } = useAuditStore((state) => state);
    const [ showAuditForm, setShowAuditForm ] = useState(false);

    useEffect(() => {
        fetchAuditLog(location.id);
    }, [location.id, fetchAuditLog]);

    const handleAfterHide = (event) => {
        if (event.target !== event.currentTarget) {
            event.preventDefault();
            return;
        } 

        setShowAuditForm(false);
    }
    
    return (
        <div className="px-2 md:px-4 py-4 border rounded-lg">
            { showAuditForm &&
                <SlDialog 
                    label="Add audit entry" 
                    open={ showAuditForm } 
                    onSlAfterHide={ handleAfterHide }
                    onSlRequestClose={ handleRequestClose }
                >
                    <Suspense fallback={ <div>Loading...</div> }>
                        <AuditEntryForm 
                            location={ location } 
                            closeModal={ () => setShowAuditForm(false) }
                        />
                    </Suspense>
                </SlDialog>
            }

            <div className="flex justify-between mb-2">
                <h3 className="mb-2">Audit log</h3>
                <div className="-mt-2 z-30">
                    <span className="text-sm text-gray-500 inline-block relative -top-3">
                        {loading ? 'Loading...' : `${auditLog.length} entries`}
                    </span>

                    {/* Add new audit entry button */}
                    <SlTooltip content="Add a new audit entry" placement="top">
                        <SlIcon 
                            name="clipboard-pulse" 
                            label="Add audit entry" 
                            onClick={ () => setShowAuditForm(!showAuditForm) }
                            className="bg-blue-500 text-white hover:text-gray-200 text-xl ml-2 rounded-full p-2 shadow-lg cursor-pointer"
                        />
                    </SlTooltip>
                </div>
            </div>

            { loading || auditLog.length === 0 &&
                <div className="text-base text-center text-gray-500 my-4">
                    <SlIcon name="clipboard-pulse" label="Add audit entry" className="text-2xl mr-2 top-1 relative" />
                    Create the first audit entry for this location
                </div>
            }

            { !loading && auditLog.length > 0 && (
                <div className="rounded-lg overflow-hidden">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                        <tbody>
                            { auditLog.map((audit, idx) => (
                                <AuditEntry 
                                    key={audit.id} 
                                    audit={audit} 
                                    isEven={ idx % 2 === 0 }
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}