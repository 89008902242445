import { Types } from '../types';
import { getCurrentUser } from 'src/utils/userUtils';
import { create } from 'zustand';
import { supabase } from '../utils/supabaseClient';
import { start } from 'repl';

const insertActionUser = async (action_id: number, user_ref: string) => {
    const { error } = await supabase
        .from('action_user')
        .insert(
            { action_id, user_ref }, 
            { returning: 'representation' }
        );

    if (error) {
        console.error('Error upserting action_user', error);
    }
}

const createRepeatAction = async (action: Types.Action) => {
    // create a new action with the same details but a new due date

    // get the repeat value and add it to the new due date - repeat is { value: 1, unit: 'day' }
    // unit could be day, week, month, year
    const repeat = action.repeat;
    const ndd = new Date();

    switch (repeat.unit) {
        case 'day':
            ndd.setDate(ndd.getDate() + repeat.value);
            break;
        case 'week':
            ndd.setDate(ndd.getDate() + (repeat.value * 7));
            break;
        case 'month':
            ndd.setMonth(ndd.getMonth() + repeat.value);
            break;
        case 'year':
            ndd.setFullYear(ndd.getFullYear() + repeat.value);
            break;
    }


    const new_action = {
        ...action,
        created_at: new Date(),
        start_date: new Date(),
        due_date: ndd,
        status: 'ready',
    };

    //remove the id so it creates a new action
    delete new_action.id;

    useActionStore.getState().upsertAction(new_action);
}

interface ActionState {
    actions: Types.Action[];
    upsertAction: (action: Types.Action) => void;
    fetchActions: (location_id: Number, showAll: Boolean) => void;
    deleteAction: (id: Number) => void;
    completeAction: (id: Number) => void;

    fetchContributors: (project_id: Number) => void;
    fetchMyActions: () => void;

    volunteer: (action_id: Number, user_ref: String) => void;

    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const useActionStore = create<ActionState>((set) => ({
    actions: [],

    fetchActions: async (location_id, showAll) => {
        set({ loading: true, actions: [] }); // Clear data and set loading

        let query = supabase
            .from('actions')
            .select('*')
            .eq('location_id', location_id);

        if (!showAll) query = query.neq('status', 'done'); 

        const actions = await query;

        if (actions.error) {
            console.error('Error fetching actions', actions.error);
        } else {
            // get all unique action ids
            const action_ids = actions.data.map((d) => d.id);

            const contributors = await supabase
                .from('action_user')
                .select('*')
                .in('action_id', action_ids);

            if (contributors.error) {
                console.error('Error fetching contributors', contributors.error);
                return;
            }

            // for each action, find the contributors and add them to the action object
            for (let i = 0; i < actions.data.length; i++) {
                const action = actions.data[i];
                const action_contributors = contributors.data.filter((c) => c.action_id === action.id);
                actions.data[i].contributors = action_contributors.map((c) => c.user_ref);
            }
        
            set({ actions: actions.data, loading: false });
        }
    },

    upsertAction: async (action) => {
        // get the contributors array
        const contributors = action.contributors;
        delete action.contributors;

        const { data: new_action, error } = await supabase
            .from('actions') 
            .upsert(action, { returning: 'representation', onConflict: ['id'] })
            .select();

        if (error) {
            console.error('Error upserting action', error);
            return;
        }

        if (action.status && action.status === 'done' && action.repeat) {
            createRepeatAction(action);
        }

        const action_id = action.id || new_action[0].id;

        if (contributors && contributors.length > 0) {
            // for each contributor, upsert the action_user table
            for (let i = 0; i < contributors.length; i++) {
                insertActionUser(action_id, contributors[i]);
            }
        }

        action.contributors = contributors;

        // update the state with the action that may already exist - if it doesn't add it to the actions array
        set((state) => {
            const actions = state.actions.map((a) => {
                if (a.id === action_id) return new_action[0];
                return a;
            });

            if (!state.actions.find((a) => a.id === action_id)) actions.push(new_action[0]);

            return { actions: actions };
        });
    },

    deleteAction: async (id) => {
        const { error } = await supabase
            .from('actions')
            .delete()
            .eq('id', id);

        if (error) {
            console.error('Error deleting action', error);
        } else {
            set((state) => {
                return {
                    ...state,
                    actions: state.actions.filter((a) => a.id !== id),
                };
            });
        }
    },

    completeAction: async (id) => {
        const { data, error } = await supabase
            .from('actions')
            .update({ status: 'done' })
            .eq('id', id)
            .select();

        const action = data[0];

        if (action.repeat) {
            createRepeatAction(action);
        }

        if (error) {
            console.error('Error completing action', error);
        } else {
            set((state) => {
                const actions = state.actions.map((action) => {
                    if (action.id === id) {
                        return { ...action, status: 'done' };
                    }

                    return action;
                });

                return { ...state, actions: actions };
            });
        }
    },

    fetchContributors: async (action_id) => {
        const { data, error } = await supabase
            .from('action_user')
            .select('*')
            .eq('action_id', action_id);

        if (error) {
            console.error('Error fetching contributors', error);
        } else {
            //find action in state and update the contributors
            set((state) => {
                const actions = state.actions.map((action) => {
                    if (action.id === action_id) {
                        return {
                            ...action,
                            contributors: data.map((d) => d.user_ref),
                        };
                    }

                    return action;
                });

                return {
                    ...state,
                    actions: actions,
                };
            });
        }
    },

    fetchMyActions: async () => {
        const userRef = await getCurrentUser();
        const { data, error } = await supabase
            .from('action_user')
            .select('*')
            .eq('user_ref', userRef);

        if (error) {
            console.error('Error fetching my actions', error);
        } else {
            const action_ids = data.map((d) => d.action_id);

            const actions = await supabase
                .from('actions')
                .select('*')
                .in('id', action_ids);

            if (actions.error) {
                console.error('Error fetching actions', actions.error);
                return;
            }

            set({ actions: actions.data });
        }
    },

    volunteer: async (action_id, user_ref) => {
        const { error } = await supabase
            .from('action_user')
            .upsert({ action_id, user_ref }, { returning: 'representation' });

        if (error) {
            console.error('Error volunteering', error);
        } else {
            set((state) => {
                const actions = state.actions.map((action) => {
                    if (action.id === action_id) {
                        const contributors = action.contributors || [];

                        return {
                            ...action,
                            contributors: [...contributors, user_ref],
                        };
                    }

                    return action;
                });

                return {
                    ...state,
                    actions: actions,
                };
            });
        }
    },

    loading: false,
    setLoading: (loading) => set({ loading }),
}));

export default useActionStore;