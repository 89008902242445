import { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';

export default function useAuth() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the initial session when the component mounts
        const fetchSession = async () => {
            const { data } = await supabase.auth.getSession();
            setUser(data?.session?.user ?? null);
            setLoading(false); // Only set loading to false after session fetch
        };

        fetchSession();

        // Listen to auth state changes (e.g., login, logout)
        const { data: listener } = supabase.auth.onAuthStateChange(
            (event: string, session: { user: any }) => {
                setUser(session?.user || null);
                setLoading(false); // Update loading when auth state changes
            }
        );

        // Cleanup the listener on component unmount
        return () => {
            listener.subscription.unsubscribe();
        };
    }, []);

    return { user, userLoading: loading };
}


