import { Types } from '../types';
import formatcoords from 'formatcoords';

type LatLngProps = {
    location: Types.Location;
};

export default function LatLng({ location }: LatLngProps) {
    const converted = formatcoords(location.lat, location.lng).format();

    return (
        <div className="text-xs text-gray-600">
            { converted }
        </div>
    );
}