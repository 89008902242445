import { useEffect, useState } from 'react';
import { useModalStore } from '../stores/modalStore';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';

export default function Modal() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { modalOpen, setModalOpen, modalContent, updateModal, modalTitle, clickOutsideClose } = useModalStore((state) => state);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleRequestClose = (event: any) => {
        if (event.target !== event.currentTarget) {
            event.preventDefault();
        } else {
            // Close the modal and clear content immediately
            setModalOpen(false);
            updateModal(false, null, '');
        }
    };

    const handleRequestAltClose = (event) => {
        if (clickOutsideClose) return;

        // Prevent the dialog from closing when the user clicks on the overlay
        if (event.detail.source === 'overlay') {
            event.preventDefault();
        }
    }

    return (
        <>
            <SlDialog 
                className="responsive-dialog"
                noHeader={ true } 
                label={ modalTitle } 
                open={ modalOpen } 
                onSlAfterHide={ handleRequestClose }
                onSlRequestClose={ handleRequestAltClose }
                style={{
                    '--width': isMobile ? '99vw' : '1302px',
                    '--height': isMobile ? '99vh' : '80vh',
                    maxWidth: 'none',
                    maxHeight: 'none'
                }}
            >
                <div className="mb-4">
                    { modalOpen && modalContent }
                </div>
            </SlDialog>
        </>
    );
}