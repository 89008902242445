import { useLocationsStore } from 'src/stores/locationStore';

export default function Logo() { 
    const { setShowList } = useLocationsStore((state) => state);

    return (
        <svg 
            height="50"
            width="50" 
            viewBox="0 0 180 213.64277098790052"
            className="ml-2 cursor-pointer"
            onClick={() => setShowList(true)}
        >
            <g 
                transform="matrix(0.6001499746989647,0,0,0.60,10,-6.686865413668)" 
                fill="#e4f9f5"
            >
                <path 
                    xmlns="http://www.w3.org/2000/svg" 
                    d="M10.925,227.077c-.513,0-1.02-.158-1.448-.462-.66-.469-1.052-1.228-1.052-2.038V115.021c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5v106.039l65.16-22.681c.467-.163,.969-.184,1.448-.06l84.839,21.935,71.828-26.921V54.343l-70.908,23.335c-.51,.168-1.061,.168-1.568-.002l-34.241-11.364c-1.311-.435-2.021-1.85-1.586-3.16,.436-1.31,1.853-2.021,3.16-1.585l33.457,11.104,73.405-24.157c.76-.251,1.598-.122,2.247,.349,.649,.47,1.034,1.224,1.034,2.025V195.066c0,1.042-.646,1.975-1.623,2.341l-74.189,27.806c-.481,.18-1.005,.208-1.503,.08l-84.87-21.943L11.747,226.938c-.269,.093-.546,.139-.822,.139Zm105.331-59.33c-12.864,0-26.067-7.406-39.277-22.04-.926-1.025-.845-2.606,.181-3.531,1.023-.926,2.605-.845,3.53,.18,12.74,14.112,25.196,20.946,37.022,20.353,12.717-.653,21.808-9.882,30.599-18.807,8.871-9.006,18.044-18.319,30.818-18.344h.052c14.071,0,28.205,11.515,42.016,34.23,.718,1.18,.343,2.718-.837,3.435-1.179,.717-2.717,.342-3.435-.837-12.841-21.119-25.54-31.828-37.744-31.828h-.042c-10.688,.021-18.74,8.197-27.267,16.853-9.461,9.606-19.245,19.539-33.904,20.292-.569,.029-1.142,.044-1.712,.044Zm-52.418-34.331c-.701,0-1.372-.295-1.846-.814-1.538-1.684-37.667-41.699-37.667-81.947,0-21.788,17.726-39.513,39.513-39.513s39.514,17.726,39.514,39.513c0,37.115-36.071,80.067-37.607,81.878-.464,.548-1.143,.869-1.86,.882-.016,0-.03,0-.046,0Zm0-117.274c-19.03,0-34.513,15.482-34.513,34.513,0,33.138,26.958,67.469,34.45,76.368,7.408-9.354,34.576-45.751,34.576-76.368,0-19.031-15.482-34.513-34.514-34.513Zm0,57.663c-12.765,0-23.149-10.385-23.149-23.149s10.385-23.149,23.149-23.149,23.149,10.385,23.149,23.149-10.385,23.149-23.149,23.149Zm0-41.299c-10.008,0-18.149,8.142-18.149,18.149s8.142,18.149,18.149,18.149,18.149-8.142,18.149-18.149-8.142-18.149-18.149-18.149Z"
                    stroke="#e4f9f5" 
                    strokeWidth="6"
                >
                </path>
            </g>
        </svg>
    );
}