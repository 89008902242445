import { useState, useEffect } from 'react';
import Logo from '../partials/Logo';
import Name from '../partials/Name';
import { useUserStore } from '../stores/userStore';
import useProjectStore from '../stores/projectStore';
import useAppStore from 'src/stores/appStore';
import { userInitials, getAvatarUrl } from '../utils/userUtils';

import SlAvatar from '@shoelace-style/shoelace/dist/react/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@awesome.me/kit-5d73c3b0cb/icons/classic/light';

export default function Header() {
    const { user, fetchCurrentUser } = useUserStore((state) => state);
    const { currentProject, fetchCurrentProject } = useProjectStore((state) => state);
    const { setOptionsDrawerOpen } = useAppStore((state) => state);
    const [userAvatar, setUserAvatar] = useState(null);

    useEffect(() => {
        if (!user) fetchCurrentUser();
        if (!currentProject) fetchCurrentProject();
    }, []);

    useEffect(() => {
        const fetchAvatar = async () => {
            const avatarUrl = await getAvatarUrl();
            setUserAvatar(avatarUrl);
        };
    
        fetchAvatar();
    }, [user]);

    return (
        <header className="flex text-3xl font-normal bg-commspot_dark w-full text-center text-white h-12 z-20 shadow-xl absolute">
            <div className="py-2 flex">
                <Logo />
                <Name />
            </div>

            <div className="hidden md:block px-4 text-xl font-semibold opacity-90 mt-[10px]">
                { currentProject?.name || '' }
            </div>

            <div className="flex-grow"></div>

            <div className="flex align-middle py-2 self-center mt-[-1px]">
                <SlAvatar
                    className="mr-3"
                    initials={ user ? userInitials(user) : null }
                    style={{ '--size': '36px' }} 
                    image={ userAvatar }
                />
            </div>

            <div className="px-4 self-center">
                <FontAwesomeIcon icon={faBars} 
                    onClick={() => setOptionsDrawerOpen(true)}
                    className="cursor-pointer text-2xl"
                />
            </div>
        </header>
    );
}