import { Types } from '../types';
import { create } from 'zustand';
import { supabase } from '../utils/supabaseClient';
import { getCurrentUser } from '../utils/userUtils';

async function getUser(userRef: string) {
    const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_ref', userRef);

    if (error) {
        console.error('Error fetching audits:', error);
        return [];
    }

    return data;
}

async function upsertSupabaseUser(user: Types.User) {
    const { data, error } = await supabase
        .from('users') 
        .upsert(user, { returning: 'representation', onConflict: ['id'] })
        .select();

    if (error) {
        console.error('Error upserting user in Supabase:', error);
        return null;
    }
    
    // Return the upserted data (which includes new IDs for inserted rows)
    return data;
}

export interface UserState {
    user: Types.User | null;
    fetchCurrentUser: () => void;
    upsertUser: (user: Types.User) => void;

    users: Types.User[];
}

export const useUserStore = create<UserState>((set) => ({
    user: null,
    fetchCurrentUser: async () => {
        const user_id = await getCurrentUser();

        if (!user_id) return;

        const user = await getUser(user_id);
        if (user && user.length > 0) {
            set({ user: user[0] });
        } else {
            console.error('Failed to fetch user from Supabase');
        }
    },

    users: [],
    upsertUser: async (user: Types.User) => {
        const upsertedUser = await upsertSupabaseUser(user);

        if (upsertedUser && upsertedUser.length > 0) {
            set((state) => {
                const updatedUsers = state.users.some((l) => l.id === upsertedUser[0].id)
                    ? state.users.map((l) => l.id === upsertedUser[0].id ? upsertedUser[0] : l)
                    : [...state.users, upsertedUser[0]];

                return { users: updatedUsers };
            });

            return upsertedUser[0];
        } else {
            console.error('Failed to upsert user in Supabase');
            return null;
        }
    },
}));
