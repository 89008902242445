import { supabase } from 'src/utils/supabaseClient';
import { getCurrentUser } from 'src/utils/userUtils';
import { useMapStore } from '../mapStore';
import useProjectStore from '../projectStore';
import { getConvexHull } from 'src/utils/mapUtils';

export async function getLocationLocations(view: any, restrictToBounds: boolean) {
    let query = restrictToBounds 
        ? getLocationsWithinBounds('get_locs_in_bounds') 
        : supabase.from('locations_extended').select('*');

    const locations = await getLocationsByView(view, query);

    if (!locations) return [];

    if (locations.error) {
        console.error(locations.error);
        return [];
    }

    return locations;
}

const getLocationsByView = async (view: any, query: any) => {
    const user_ref =  await getCurrentUser();

    if (!user_ref) return [];

    const currentProject = useProjectStore.getState().currentProject;

    switch (view.owner) {
        case 'mine':
            query = query.eq('created_by', user_ref);
            break;
        case 'watching':
            const watching = await getWatching();
            query = query.in('id', watching);
            break;
        case 'project':
            if (!currentProject) return [];
            query = query.eq('project_id', currentProject?.id);
            break;
        case 'myProjects':
            if (!currentProject) return [];
            const projects = await getMyProjects();
            query = query.in('project_id', projects);
            break;
        case 'public':
            query = query.eq('access', 'public');
            break;
        default:
            break;
    }

    if (view.type !== 'all') query = query.eq('type', view.type); 

    const locations = await query;
    return locations;
}

/** returns a prepared sup[abase function query] */
const getLocationsWithinBounds = (func) => {
    const funcArgs = getBoundsFunctionArgs();

    let query = supabase
        .rpc(func, funcArgs);

    return query;
}

export async function getActionLocations(view: any, restrictToBounds: boolean) {    
    const locations = await getActionsByView(view, restrictToBounds);

    if (!locations) return [];

    if (locations.error) {
        console.error(locations.error);
        return [];
    }

    return locations;
}

const getActionsByView = async (view: any, restrictToBounds: boolean) => {
    const user_ref =  await getCurrentUser();
    if (!user_ref) return [];

    let query = restrictToBounds 
        ? getLocationsWithinBounds('get_action_locs_in_bounds') 
        : supabase.from('actions_extended').select('*');

    let projects = [];

    if (view.owner === 'helping') projects = await getProjectsWithActionsVolunteeredFor();
    if (view.owner === 'project') projects = await getMyProjects();

    query = query
        .is('deleted_at', null)
        .is('action_deleted_at', null)
        .neq('action_status', 'done');

    switch (view.owner) {
        case 'mine':
            query = query.eq('action_created_by', user_ref);
            break;
        case 'helping':
            query = query.in('project_id', projects);
            break;
        case 'project':
            query = query.in('project_id', projects);
            break;
        case 'public':
            query = query.eq('project_public', 'public');
            break;
        default:
            break;
    }

    const locations = await query;
    return locations;
}

export const getProjectLocations = async (view: any, restrictToBounds: boolean) => {
    const user_ref = await getCurrentUser();
    if (!user_ref) return [];

    let query = restrictToBounds 
        ? getLocationsWithinBounds('get_project_locations_within_bounds') 
        : supabase.from('projects_extended').select('*');

    switch (view.owner) {
        case 'mine':
            query = query.eq('owner', user_ref);
            break;
        case 'member':
            const proj_ids = await getMyProjects();
            query = query.in('project_id', proj_ids);
            break;
        case 'public':
            query = query.eq('access', 'public');
            break;
        default:
            break;
    }

    const projects = await query;

    if (!projects) return [];

    // process the project locations to have a polygon of the area or lat lng
    projects.data = processProjectLocations(projects);

    return projects;
}

const getBoundsFunctionArgs = () => {
    const bounds = getBounds();

    if (!bounds) {
        console.error('Error fetching locations: bounds are undefined');
        return;
    }

    const { _sw, _ne } = bounds;

    return {
        min_lng: _sw.lng,
        min_lat: _sw.lat,
        max_lng: _ne.lng,       
        max_lat: _ne.lat,
    };
}

const getBounds = () => {
    const mapBounds = useMapStore.getState().mapBounds;
    return mapBounds;
}


const getMyProjects = async () => {
    const user_ref = await getCurrentUser();
    if (!user_ref) return [];

    const projects = await supabase
        .from('project_user')
        .select('id')
        .eq('user_ref', user_ref);

    const project_ids = projects.data.map((project) => project.id);

    return project_ids;
}

const getActionsVolunteeredFor = async () => {
    const user_ref = await getCurrentUser();
    if (!user_ref) return [];

    const actions = await supabase
        .from('action_user')
        .select('*')
        .eq('user_ref', user_ref);

    return actions.data;
}

const getActions= async (actions) => {
    if (!actions) return [];

    const action_ids = actions.map((action) => action.id);

    const data = await supabase
        .from('action_locations')
        .select('*')
        .in('action_id', action_ids);

    return data.data;
}

const getProjectsWithActionsVolunteeredFor = async () => {
    const actions = await getActionsVolunteeredFor();
    const actionLocations = await getActions(actions);

    if (!actionLocations) return [];

    const project_ids = actionLocations.map((action) => action.project_id);

    return project_ids;
}

const getWatching = async () => {
    const user_ref = await getCurrentUser();
    if (!user_ref) return [];

    const watching = await supabase
        .from('watched_locations')
        .select('id')
        .eq('user_id', user_ref);

    if (!watching) return [];

    return watching.data.map((watch) => watch.id);
}
const processProjectLocations = (projects) => {
    if (!projects.data) return [];

    const data = projects.data;

    for (let i=0; i < data.length; i++) {
        const project = data[i];
        let allCoords = [];

        if (project.locs) {
            const locs = project.locs.split(', ');

            // single locations: use that lat lng
            if (locs.length === 1) {
                project.lat = locs[0].split(' ')[0];
                project.lng = locs[0].split(' ')[1];   
            }
            
            if (locs.length > 1) {
                locs.forEach((loc) => {
                    const lc = loc.split(' ');
                    allCoords.push([ parseFloat(lc[1]), parseFloat(lc[0])]);
                });
            }
        }

        // merge the coordinates from locs and the polygons
        if (project.polygons) {
            // strip any whitespace
            project.polygons = project.polygons.replace(/\s/g, '');
            const coords = JSON.parse('['+project.polygons+']');

            coords.forEach((coord) => {
                allCoords = [...allCoords, ...coord[0]];
            });
        }

        const hull = getConvexHull(allCoords);
        project.polygon = JSON.stringify(hull);
    };

    return data;
}