import { create } from 'zustand';

interface ModalState {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
    closeModal: () => void;

    clickOutsideClose: boolean; // Allow closing the modal by clicking outside of it

    modalTitle: string;
    setModalTitle: (title: string) => void;
    
    modalContent: any;
    setModalContent: (content: any) => void;
    
    updateModal: (open: boolean, content: any, title?: string) => void;
}

export const useModalStore = create<ModalState>((set) => ({
    modalOpen: false,
    setModalOpen: (open: boolean) => set({ modalOpen: open }),

    closeModal: () => set({ modalOpen: false, modalTitle: '', modalContent: null }),

    clickOutsideClose: true,

    modalTitle: '',
    setModalTitle: (title: string) => set({ modalTitle: title }),

    modalContent: null,
    setModalContent: (content: any) => set({ modalContent: content }),

    updateModal: (open: boolean, content: any, title?: string, clickOutsideClose?: boolean) => {
        set({ modalOpen: false, modalTitle: '', modalContent: null }); // Clear existing content
        set({ modalOpen: open, modalTitle: title, modalContent: content, clickOutsideClose: clickOutsideClose });
    }
}));