
import { createClient } from '@supabase/supabase-js';

export const supabase = createClient(
    process.env.REACT_APP_VITE_SUPABASE_URL || '',
    process.env.REACT_APP_VITE_SUPABASE_ANON_KEY || '', 
    {
        auth: {
        persistSession: true, // Ensures user session is persisted
        detectSessionInUrl: true,
        },
    }
);