import { Types } from '../types';
import { create } from 'zustand';
import { supabase } from '../utils/supabaseClient';
import { getUserNames } from '../utils/userUtils';

interface ImageState {
    images: Types.Image[];
    upsertImage: (image: Types.Image) => void;
    fetchImages: (locationId: number) => void;
    deleteImage: (id: number) => void;

    getAllImageCredits: () => void;
}

async function getImages(locationId: number) {
    const { data, error } = await supabase
        .from('attachments')
        .select('*')
        .eq('location_id', locationId);

    if (error) {
        console.error('Error fetching images:', error);
        return [];
    }

    return data;
}

async function upsertSupabaseImage(image: Types.Image) {
    const { data, error } = await supabase
        .from('attachments') 
        .upsert(image, { returning: 'representation', onConflict: ['id'] })
        .select();

    if (error) {
        console.error('Error upserting image in Supabase:', error);
        return null;
    }
    
    // Return the upserted data (which includes new IDs for inserted rows)
    return data;
}

export const useImageStore = create<ImageState>((set) => ({
    images: [],

    upsertImage: async (image: Types.Image) => {
        const upsertedImage = await upsertSupabaseImage(image);

        if (upsertedImage && upsertedImage.length > 0) {
            set((state) => {
                const updatedImages = state.images.some((l) => l.id === upsertedImage[0].id)
                    ? state.images.map((l) => l.id === upsertedImage[0].id ? upsertedImage[0] : l)
                    : [...state.images, upsertedImage[0]];

                return { images: updatedImages };
            });

            return upsertedImage[0];
        } else {
            console.error('Failed to upsert image in Supabase');
            return null;
        }
    },

    fetchImages: async (locationId: number) => {
        set({ images: [] });
        const data = await getImages(locationId);
        set({ images: data });
    },

    deleteImage: async (id: number) => {
        // find the image, delete the image from the bucket and the database
        const image = useImageStore.getState().images.find((i) => i.id === id);
        if (!image) return;
        
        // strip the images/ prefix
        const url = image.url.split('/')[1];
        const { error } = await supabase.storage.from('images').remove([url]);

        if (error) {
            console.error('Error deleting image from bucket:', error);
            return;
        }

        const { error: dbError } = await supabase
            .from('attachments')
            .delete()
            .eq('id', id);

        if (dbError) {
            console.error('Error deleting image:', dbError);
            return;
        }

        set((state) => {
            const updatedImages = state.images.filter((l) => l.id !== id);
            return { images: updatedImages };
        });
    },

    getAllImageCredits: async () => {
        const images = useImageStore.getState().images;
        const users = await getUserNames(images.map((i) => i.created_by));

        const updatedImages = images.map((i) => {
            const user = users.find((u) => u.user_ref === i.created_by);
            return { ...i, credit: user ? user.first_name + ' ' + user.last_name : '' };
        });

        set({ images: updatedImages });
    }

}));
