import { Types } from "../../types";
import AttrConfig from "../../config/attributeTypes.json";

interface AttributeListItemProps {
    attr: any;
}

export default function AttributeListItem({ attr }: AttributeListItemProps) {
    const getUnit = (name: string) => {
        const attr = AttrConfig[name];
        return attr?.unit ? attr.unit : '';
    }
    const getEstimated = (attr: Types.Attribute) => {
        return attr?.estimated ? ' (estimated)' : '';
    }

    const prepareValue = () => {
        return attr.value + getUnit(attr.name) + getEstimated(attr);
    }

    return (
        <>
            <div scope="row" className="capitalize py-1 px-3 font-medium text-gray-900 whitespace-nowrap">
                { attr.name }
            </div>

            <div className="grow py-1">
                { prepareValue() }
            </div>

            <div className="py-1">
            </div>
        </>
    );
}