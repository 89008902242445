import { Types } from 'src/types';
import { formatDateTime } from 'src/utils/utils';
import SlRelativeTime from '@shoelace-style/shoelace/dist/react/relative-time';

interface AuditEntryProps {
    audit: Types.Audit;
    isEven: boolean;
}

export default function AuditEntry({ audit, isEven }: AuditEntryProps) {
    const name = audit.users && `${audit.users.first_name} ${audit.users.last_name}`;

    return (
        <tr className={ `border-b  ${isEven ? 'bg-commspot_pale' : 'bg-commspot_paler'}` }>
            <td className="px-4  py-3" colSpan={4}>
                <div className="flex justify-between mb-1">
                    <div className="text-xs font-semibold">
                        <span>{ formatDateTime(new Date(audit.created_at)) }</span>
                        <span> - </span>
                        <span className="text-xs">
                            <SlRelativeTime date={ audit.created_at } sync />
                        </span>
                    </div>
                    <div className="text-xs font-light text-gray-500">
                        by { name }
                    </div>
                </div>

                <p className="text-gray-700">
                    { audit.notes }
                </p>

                { audit.changes && audit.changes.length &&
                    <div>
                        { audit.changes.map((change, index) => (
                            <div key={ index } className="text-xs text-gray-500">
                                <span className="font-semibold capitalize">{ change.name }</span>
                                <span> to </span>
                                <span className="font-semibold">{ change.value }</span>
                                { !isNaN(change.value) && change.estimated && <span> (est)</span> }
                            </div>
                        )) }
                    </div>
                }
            </td>
        </tr>
    );
}