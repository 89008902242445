import { useMemo } from 'react';
import { Types } from 'src/types';
import { getTypeColor } from 'src/utils/mapUtils';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const defaultZoom = 14;

interface MiniMapProps {
    location: Types.Location;
    height?: number;
    width?: number|string;
    zoom?: number;
}

export default function MiniMap({ location, height, width, zoom }: MiniMapProps) {
    width = width || 200;
    height = height || 200;

    const { lat, lng, polygon } = location;
    const coords = (polygon && typeof polygon == 'string') ? JSON.parse(polygon) : polygon;

    // Function to calculate the centroid of a polygon
    const centroid = useMemo(() => {
        if (!polygon) return { lat, lng };

        if (!coords || coords.length === 0) return { lat: 0, lng: 0 };

        let area = 0;
        let x = 0;
        let y = 0;

        const data = coords[0]; // Assuming first array is the outer boundary of the polygon

        for (let i = 0, len = data.length - 1; i < len; i++) {
            const [x1, y1] = data[i];
            const [x2, y2] = data[i + 1];
            const f = x1 * y2 - x2 * y1;
            area += f;
            x += (x1 + x2) * f;
            y += (y1 + y2) * f;
        }

        area /= 2;
        const factor = area * 6;
        return { lat: y / factor, lng: x / factor };
    }, [coords]);

    const viewState = {
        latitude: centroid?.lat || 0,
        longitude: centroid?.lng || 0,
        zoom: zoom || defaultZoom,
    };

    // Create a GeoJSON source for the polygon
    const polygonGeoJSON = polygon
        ? {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: coords,
            },
        }
        : null;
        
    return (
        <div className="mini-map rounded-lg overflow-hidden" style={{ height: height || 200, width: width || 200 }}>
            <Map
                reuseMaps
                onRender={(event) => event.target.resize()}
                viewState={viewState}
                style={{ width: '100%', height: '100%' }} // Set the size of the mini-map
                mapStyle="mapbox://styles/mapbox/streets-v11" // Mapbox style
                mapboxAccessToken={MAPBOX_TOKEN}
                attributionControl={false} // Disable attribution control
            >
                {/* Render a marker if lat and lng are provided */}
                {lat && lng && <Marker longitude={lng} latitude={lat} />}

                {/* Render the polygon if it exists */}
                { polygonGeoJSON && (
                    <Source id="polygon" type="geojson" data={polygonGeoJSON}>
                        <Layer
                            id="polygon-layer"
                            type="fill"
                            paint={{
                                'fill-color': getTypeColor(location),
                                'fill-opacity': 0.4,
                            }}
                        />
                        <Layer
                            id="polygon-outline-layer"
                            type="line"
                            paint={{
                                'line-color': '#000000',
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}
            </Map>
        </div>
    );
}
