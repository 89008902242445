import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocationDot } from '@awesome.me/kit-5d73c3b0cb/icons/classic/light';

export default function Loading() {
    return (
        <div className="flex mx-auto justify-center items-center h-[100vh] w-[100wh] ">
            <FontAwesomeIcon 
                icon={faMapLocationDot} 
                className="text-7xl text-commspot_dark fa-beat-fade"
            />
        </div>
    );
}