import { useEffect, useState, lazy, Suspense } from 'react';
import './App.css';
import { getUserRecord } from './utils/userUtils';
import useAppStore from './stores/appStore';
import useProjectStore from './stores/projectStore';

import Header from './components/Header';
import Modal from './components/Modal';
import Loading from './components/Loading';
import useAuth from './hooks/auth';
import { getUserSession, getCurrentProject } from './utils/userUtils';

// Shoelace CSS
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.17.1/cdn/');

const LocationDrawer = lazy(() => import('./components/locations/LocationDrawer'));
const CSMap = lazy(() => import('./components/map/Map'));
const OnBoard = lazy(() => import('./components/user/OnBoard'));
const OptionsDrawer = lazy(() => import('./components/OptionsDrawer'));
const SignIn = lazy(() => import('./components/SignIn'));
const FirstUseModal = lazy(() => import('./components/FirstUseModal'));
const InviteModal = lazy(() => import('./components/projects/InviteModal'));

function App() {
    const { setLatestVersion, firstUse, setFirstUse, invites, fetchInvites, getConfig } = useAppStore((state) => state);
    const { setCurrentProject } = useProjectStore((state) => state);
    const [userRecord, setUserRecord] = useState(null);
    const [sessionUser, setSessionUser] = useState(null);
    const [userLoaded, setUserLoaded] = useState(false);
    const { user, userLoading } = useAuth();

    useEffect(() => {
        setLatestVersion();
        getConfig();

        const firstUse = localStorage.getItem('firstUse');
        if (!firstUse) setFirstUse(true);

        (async () => {
            const project = await getCurrentProject();
            setCurrentProject(project);
        })();
    }, []);


    useEffect(() => {
        if (!user) {
            (async () => {
                const user = await getUserSession();
                setSessionUser(user);
            })();
            return;
        }

        getUserRecord(user.id).then((record) => {
            setUserRecord(record);
            setUserLoaded(true);
            fetchInvites(record.email);
        });
    }, [user]);

    if (userLoading) return <Loading />;
    if (!user && !sessionUser) return <SignIn />;
    if (!userRecord && userLoaded && sessionUser) return <OnBoard user={sessionUser} />;

    if (!userRecord) return <Loading />;

    if (!userRecord?.first_name || !userRecord?.last_name || !userRecord?.email) {
        return  <OnBoard user={userRecord} />
    }

    return (
        <div className="App">
            <Header />

            <main className="App-main">
                <Suspense fallback={<p>Loading...</p>}>
                    <LocationDrawer />
                </Suspense>

                <CSMap />
                
                <Suspense fallback={<p>Loading...</p>}>
                    <OptionsDrawer />
                </Suspense>
            </main>

            <Modal />
            { firstUse && <FirstUseModal /> }
            { invites && userRecord && <InviteModal email={userRecord.email} /> }
        </div>
    );
}

export default App;
