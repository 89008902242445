import { create } from 'zustand';
import { Types } from '../types';
import { config } from '../config/app';

export interface MapState {
    mapCenter: Types.LatLngLiteral | null;
    setMapCenter: (center: Types.LatLngLiteral) => void;
    
    mapZoom: number;
    setMapZoom: (zoom: number) => void;
    
    mapBounds: Types.LatLngBounds | null;
    setMapBounds: (bounds: Types.LatLngBounds) => void;

    style: string;
    setStyle: (style: string) => void;
    mapUrl: () => string;

    modes: {
        isDrawingMode: boolean;
        drawingInProgress: boolean;
        ignoreNextClick: boolean;
        mapLoaded: boolean;
        isDragging: boolean;
    };
    setModes: (mode: { [key: string]: boolean }) => void;

    polygonStateData: Types.GeoJSON;
    setPolygonStateData: (data: Types.GeoJSON) => void;

    flyToProjectLocations: Boolean;
    setFlyToProjectLocations: (fly: Boolean) => void;

    pulseLocationId: number | null;
    setPulseLocationId: (id: number) => void;
}

export const useMapStore = create<MapState>((set) => ({
    mapCenter: null,
    setMapCenter: (center) => set({ mapCenter: center }),

    mapZoom: 8,
    setMapZoom: (zoom) => set({ mapZoom: zoom }),

    mapBounds: null,
    setMapBounds: async (bounds) => {
        await set({ mapBounds: bounds });
    },

    style: config.default_map_style,
    setStyle: (style) => set({ style: style }),
    mapUrl: () => {
        const mapOptions = config.map_options;
        const { style } = useMapStore.getState();
        return mapOptions[style].url;
    },

    modes: {
        isDrawingMode: false,
        drawingInProgress: false,
        ignoreNextClick: false,
        mapLoaded: false,
        isDragging: false,
    },
    setModes: (mode) => {
        set((state) => { 
            return { modes: { ...state.modes, ...mode } } 
        });
    },

    polygonStateData: { type: 'FeatureCollection', features: [] },
    setPolygonStateData: (data) => set({ polygonStateData: data }),

    flyToProjectLocations: false,
    setFlyToProjectLocations: (fly) => set({ flyToProjectLocations: fly }),

    pulseLocationId: null,
    setPulseLocationId: (id) => set({ pulseLocationId: id }),
}));