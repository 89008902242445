import { Types } from '../types';
import proj4 from "proj4";
import { supabase } from './supabaseClient';
import { getPolygonCenter } from './mapUtils';

export async function getMetaData(location: Types.Location) {
    let coords = {};

    return;  //TEMP - remove this line to enable the function - need to work out MapIt licensing
    if (location.polygon) {
        const polygon = JSON.parse(location.polygon)
        const { lat, lng } = getPolygonCenter(polygon[0], false);
        coords.lat = lat;
        coords.lng = lng;
    }
    else {
        coords = location;
    }

    const bng = convertLatLngToBNG(coords.lat, coords.lng);

    console.log('Getting metadata for location:', location.name, bng);

    const [osData, mapItData] = await Promise.all([
        getOSdata(bng),
        getMapItData(bng)
    ]);

    // save to location
    const { error } = await supabase
        .from('locations')
        .update({
            os_data: osData,
            mapit_data: mapItData,
        })
        .eq('id', location.id);

    if (error) {
        console.error('Error updating location:', error);
    }
}


const getOSdata = async (bng) => {
    // Define request parameters.
    const params = {
        key: process.env.REACT_APP_OS_API_KEY,
        point: Number(bng.easting.toFixed(2)) + ',' + Number(bng.northing.toFixed(2)),
    };

    const queryString = Object.keys(params).map(function(key) {
        return key + '=' + params[key];
    }).join('&');

    try {
        const response = await fetch('https://api.os.uk/search/names/v1/nearest?' + queryString);
        const data = await response.json();

        if (data.header.totalresults > 0) {
            return data.results[0].GAZETTEER_ENTRY;
        }

        return 'Nothing found';

    } catch (error) {
        console.error("Error fetching data:", error);
        return 'Error fetching data';
    }
};

const getMapItData = async (bng) => {
    try {
        const response = await fetch('https://mapit.mysociety.org/point/27700/' + bng.easting + ',' + bng.northing);
        const data = await response.json();

        return data;

    } catch (error) {
        console.error("Error fetching data:", error);
        return 'Error fetching data';
    }
}

const convertLatLngToBNG = (lat: number, lng: number) => {
    // set coord defs
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");  // WGS84
    proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");  // British National Grid

    const [convertedEasting, convertedNorthing] = proj4("EPSG:4326", "EPSG:27700", [lng, lat]);

    return {
        easting: convertedEasting,
        northing: convertedNorthing,
    };
}