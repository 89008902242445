import { useMemo } from 'react';
import { Types } from '../../types'
import AttributeListItem from './AttributeListItem';
import TypeField from './TypeField';
import DescriptionField from './DescriptionField';
import NameField from './NameField';

interface AttributeListProps {
    location: Types.Location;
};

export default function AttributeList({ location }: AttributeListProps) {
    const attributes = useMemo(() => location.attributes || [], [location.attributes]);

    if (!location) return null;
    
    return (
        <div className="py-2 bg-commspot_pale rounded-lg mb-2">
            <div className="grid grid-cols-[auto,1fr,40px] gap-y-1 w-full text-sm text-left rtl:text-right text-gray-600">
                <NameField location={ location } />
                <TypeField location={ location } />
                <DescriptionField location={ location } />

                { attributes.map((attr: any, index: number) => (
                    <AttributeListItem 
                        key={ index }
                        attr={ attr }
                        location={ location }
                    />
                ))}
            </div>

            <div className="text-xs text-gray-600 text-center mt-2">
                Create an audit entry to update these attributes
            </div>
        </div>
    )
}