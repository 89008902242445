import { create } from 'zustand';
import { Types } from '../types';
import { supabase } from 'src/utils/supabaseClient';
import { getCurrentProject, setCurrentProject, getCurrentUser } from '../utils/userUtils';
import { useLocationsStore } from './locationStore';

interface ProjectState {
    project: Types.Project | null;
    fetchProject: (id: Number) => void;
    upsertProject: (project: Types.Project) => void;

    currentProject: Types.Project | null;
    setCurrentProject: (project: Types.Project) => void;
    setProjectById: (projectId: Number) => void;
    fetchCurrentProject: () => void;

    projects: Types.Project[];
    fetchProjects: () => void;

    contributors: Types.User[];
    fetchContributors: (projectId: Number) => void;

    invites: Types.Invite[];
    fetchInvites: (projectId: Number) => void;
    deleteInvite: (inviteId: Number) => void;
}

const useProjectStore = create<ProjectState>((set) => ({
    project: null,
    fetchProject: async (id: Number) => {
        const { data, error } = await supabase
            .from('projects')
            .select('*')
            .eq('id', id)
            .single();

        if (error) {
            console.error('Error fetching project:', error);
            return null;
        }

        set({ project: data });
    },
    upsertProject: async (project: Types.Project) => {
        const { data, error } = await supabase
            .from('projects')
            .upsert(project, { returning: 'representation', onConflict: ['id'] })
            .select();

        if (error) {
            console.error('Error upserting project in Supabase:', error);
            return null;
        }

        // update the project in the store
        set({ project: data[0] });

        // update this project in projects
        set((state) => {
            const projects = state.projects.map((p) => {
                if (p.id === data[0].id) return data[0];
                return p;
            });

            return { projects };
        });

        return data[0].id;
    },

    currentProject: null,
    setCurrentProject: async (project: Types.Project) => {
        await setCurrentProject(project);

        // view / filters back to current project
        const { setShowLocationType, changeView } = useLocationsStore.getState();
        setShowLocationType('locations');

        changeView({ owner: 'project' });

        set({ currentProject: project });
    },
    setProjectById: async (id: Number) => {
        const project = await supabase
            .from('projects')
            .select('*')
            .eq('id', id)
            .single();

        if (project.data) {
            useProjectStore.getState().setCurrentProject(project.data);
        }

        return project.data;
    },

    fetchCurrentProject: async () => {
        // we should have the project in local storage if the user has already selected one
        const project_ls = await getCurrentProject();

        if (project_ls) {
            set({ project: project_ls });
            return;
        }

        // if not,get all projects and pick the first one
        const projects = await supabase
            .from('projects')
            .select('*');

        if (projects.error) {
            console.error('Error fetching projects:', projects.error);
            return;
        }

        if (projects.data && projects.data.length > 0) {
            const project = projects.data[0];
            await setCurrentProject(project);

            set({ currentProject: project });
        }
    },

    projects: [],
    fetchProjects: async () => {
        const userRef = await getCurrentUser();
    
        const [ownerOrPublicProjectsResult, userAssociatedProjectsResult] = await Promise.all([
            supabase
                .from('projects')
                .select('*')
                .or(`owner.eq.${userRef},access.eq.public`),
            supabase
                .from('projects')
                .select('*, project_user!inner(user_ref)')
                .eq('project_user.user_ref', userRef),
        ]);
        
        const { data: ownerOrPublicProjects, error: error1 } = ownerOrPublicProjectsResult;
        const { data: userAssociatedProjects, error: error2 } = userAssociatedProjectsResult;
        
        if (error1 || error2) {
            console.error('Error fetching projects:', error1 || error2);    
            return [];
        }
        
        // Combine and deduplicate results
        const combinedProjects = [
            ...new Map(
                [...(ownerOrPublicProjects || []), ...(userAssociatedProjects || [])].map(item => [item.id, item])
            ).values(),
        ];
        
        set({ projects: combinedProjects });
    },

    contributors: [],
    fetchContributors: async (projectId: Number) => {
        if (!projectId) return [];

        const userRefs = await supabase
            .from('project_user')
            .select('user_ref')
            .eq('project_id', projectId);

        if (userRefs.error) {
            console.error('Error fetching project members:', userRefs.error);
            return [];
        }

        const contributorIds = userRefs.data.map((m: any) => m.user_ref);

        const { data: users, error: userError } = await supabase
            .from('users')
            .select('*')
            .in('user_ref', contributorIds);

        if (userError) {
            console.error('Error fetching users:', userError);
            return [];
        }

        set({ contributors: users });
    },

    invites: [],
    fetchInvites: async (projectId: Number) => {
        if (!projectId) return [];

        const { data, error } = await supabase
            .from('invites')
            .select('*')
            .eq('type', 'project')
            .eq('type_id', projectId)
            .is('accepted', null);

        if (error) {
            console.error('Error fetching invites:', error);
            return [];
        }

        set({ invites: data });
    },

    deleteInvite: async (inviteId: Number) => {
        const { error } = await supabase
            .from('invites')
            .delete()
            .eq('id', inviteId);

        if (error) {
            console.error('Error deleting invite:', error);
            return;
        }
    }
}));

export default useProjectStore;