import { Types } from 'src/types';
import { config } from 'src/config/app';
import typesData from 'src/config/types.json';

const types: Types.TypeConfig = typesData;

// format datetime to dd MMM yyyy HH:mm
export const formatDateTime = (date: Date): string => {
    return new Date(date).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
};

// format date to dd MMM yyyy
export const formatDate = (date: Date): string => {
    return new Date(date).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
};

// convert attributes array to assoc array for attribute picker
export const getAssocFromAttributes = (attributes: Types.Attribute[]) => {
    const assoc: Types.AttributeAssocArray = {};
    
    attributes.forEach((attr) => {
        assoc[attr.name] = attr.value;
    });

    return assoc;
}

// Filter out types that are not in a project
export const prepareTypes = (types: Types.TypeConfig, project) => {
    if (!project?.types) return Object.keys(types);

    return Object.keys(types).filter((type) => project.types.includes(type));
};

export const prepareLocationsTypes = (locations: Types.Location[]) => {
    // get all unique types from locations
    const types: string[] = [];

    if (!locations) return types;

    locations.forEach((location) => {
        if (!types.includes(location.type)) types.push(location.type);
    });

    return types;
};

export const isMobile = () => {
    return window.innerWidth < config.mobile_breakpoint;
}

export const getType = (type: string) => {
    return types[type];
}

export const loadIcon = async (iconName: string) => {
    try {
        // Import the icon dynamically using its name
        const iconModule = await import(`@awesome.me/kit-5d73c3b0cb/icons/classic/light`);
        
        if (iconModule[iconName]) {
            return iconModule[iconName];
        } else {
            // Fallback to default icon if specific one is not found
            return iconModule[iconMapping.default];
        }
    } catch (error) {
        console.error('Error loading icon:', error);
    }
}