import { create } from 'zustand';
import versions from '../config/versions';
import { checkForInvites } from 'src/utils/userUtils';
import { config } from 'src/config/app';

interface AppState {
    optionsDrawerOpen: boolean;
    setOptionsDrawerOpen: (open: boolean) => void;

    latestVersion: string;
    setLatestVersion: () => void;

    isMobile: boolean;
    setIsMobile: (isMobile: boolean) => void;

    firstUse: boolean;
    setFirstUse: (firstUse: boolean) => void;

    invites: any[];
    fetchInvites: (email: any) => void;

    config: any;
    getConfig: () => void;
}

const useAppStore = create<AppState>((set) => ({
    optionsDrawerOpen: false,
    setOptionsDrawerOpen: (open: boolean) => set({ optionsDrawerOpen: open }),

    latestVersion: '',
    setLatestVersion: () => {
        const latest = versions.slice(-1)[0];
        set({ latestVersion: latest?.ver || '' });
    },

    isMobile: window.innerWidth < 768,
    setIsMobile: (isMobile: boolean) => set({ isMobile }),

    firstUse: false,
    setFirstUse: (firstUse: boolean) => set({ firstUse }),

    invites: [],
    fetchInvites: async (email) => {
        const invites = await checkForInvites(email);

        set({ invites: invites });
    },

    config: {},
    getConfig: () => {
        set({ config: config });
    }
}));

export default useAppStore;