import { useEffect, useState } from 'react';
import FullCarousel from './FullCarousel';
import { useImageStore } from 'src/stores/imageStore';
import AddImageModal from './AddImageModal';
import { SlIcon } from '@shoelace-style/shoelace/dist/react';

const imagePath = process.env.REACT_APP_SUPABASE_IMAGE_BUCKET;

interface ImagesProps {
    location_id: number | null;
}

export default function Images({ location_id }: ImagesProps) {
    const { images, fetchImages } = useImageStore((state) => state);
    const [showCarousel, setShowCarousel] = useState(false);
    const [goto, setGoto] = useState(0);

    useEffect(() => {
        if (location_id) fetchImages(location_id);
    }, [location_id, fetchImages]);

    const handleClick = async (index) => {
        await setShowCarousel(true);
        setGoto(index); 
    };

    const ImageModal = (
        <AddImageModal 
            location_id={ location_id }
        />
    );

    if (images && images.length === 0) {
        return (
            <div className="flex h-12 border rounded-lg px-4 pt-4 pb-12">
                <div className="flex-grow text-center text-gray-500 cursor-pointer">
                    <SlIcon name="camera" className="mr-2 text-xl top-1 relative" />
                    Add some photos
                </div>

                { ImageModal}
            </div>
        );
    }

    return (
        <div className="border p-2 md:px-4 py-4 rounded-lg">
            <div className="flex h-10">
                <div className="grow">
                    Photos
                </div>

                { ImageModal }
            </div>

            <div className="w-full flex flex-wrap gap-2">
                { images && images.map((img, index) => (
                    <div key={ index } className="rounded-md h-24 overflow-hidden hover:shadow-lg">
                        <img 
                            alt={ img.txt } 
                            src={ imagePath + img.url } 
                            className="cursor-pointer w-full max-h-full object-contain rounded-md" 
                            onClick={ () => handleClick(index) }
                        />
                    </div>
                )) }
            </div>

            { showCarousel &&
                <FullCarousel 
                    hideCarousel={ () => setShowCarousel(false) }
                    images={ images }
                    goto={ goto }
                />
            }
        </div>
    );
}
