import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";

interface ConfirmationDialogProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    message?: string;
}

export default function ConfirmationDialog({ isOpen, onConfirm, onCancel, message }: ConfirmationDialogProps) {
    if (!isOpen) return null;

    return (
        <SlDialog 
            open={isOpen} 
            noHeader={true}
        >
            <div className="p-6 text-center">
                <p className="mb-4">{message || "Are you sure you want to proceed?"}</p>
                <div className="flex justify-around">
                    <SlButton
                        variant="danger"
                        onClick={onConfirm}
                        style={{ width: '6rem' }}
                    >
                        Yes
                    </SlButton>

                    <SlButton
                        onClick={onCancel}
                        style={{ width: '6rem' }}
                    >
                        No
                    </SlButton>
                </div>
            </div>
        </SlDialog>
    );
}
