import { Types } from '../types';
import { fitBounds } from '@math.gl/web-mercator';
import LocationDetails from '../components/locationDetails/LocationDetails';
import { useMapStore } from '../stores/mapStore';
import { useModalStore } from '../stores/modalStore';
import TypeConfig from '../config/types.json';
import * as turf from "@turf/turf";

// get the user geolocation, if allowed by the browser,then center the map on the user's location
export const geoCenter = (
    setMapCenter: (center: Types.LatLngLiteral) => void
) => {
    if (!navigator.geolocation) {
        console.error("Geolocation is not supported by this browser");
        return;
    }

    navigator.geolocation.getCurrentPosition(
        (position) => {
            const newCenter = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };

            if (!isNaN(newCenter.lat) && !isNaN(newCenter.lng)) {
                setMapCenter(newCenter);
            }
        },
        () => {
            console.error("Error fetching user location");
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );
}

export const createNewLocation = (
    location: Types.Location, 
    upsertLocation: (location: Types.Location, isNew: Boolean) => void
) => {
    (async () => {
        const newLoc = await upsertLocation(location, true);

        if (newLoc.lat && newLoc.lng) {
            // center the map on the new location
            useMapStore.setState({ mapCenter: { lat: newLoc.lat, lng: newLoc.lng } });
        }

        // show the location details modal on add
        useModalStore.getState().updateModal(
            true, 
            <LocationDetails location_id={ newLoc.id } />,
            newLoc.name,
            true,
        );
    })();
}

// create an array of markers from the locations array
export const collateMarkers = (
    locations?: Types.Location[]
) => {
    return locations 
        ? locations
            .filter((loc) => !isNaN(loc.lat) && !isNaN(loc.lng))
            .map((loc, idx) => ({ 
                key: idx.toString(), 
                id: loc.id,
                location: loc
            }))
        : [];
}

// Pan to clicked marker's location
export const centerMap = (newCenter: Types.LatLngLiteral, map: mapboxgl.Map) => {
    if (!map || !newCenter) return;
    map.flyTo({ center: [newCenter.lng, newCenter.lat], zoom: 16 });
};

export const getTypeColor = (location: Types.Location) => {
    // @TODO handle color config for other types
    if (location.type === 'invasive_species') {
        const status = location.attrs.find((attr) => attr.name === 'invasive-status');

        switch (status?.value) {
            case 'audit-required':
                return '#555555';
            case 'needs-clearing':
                return '#FFA500';
            case 'cleared':
                return '#06882B';
            case 'none-present':
                return '#3333EE';
            default:
                return '#555555';
        }
    }

    return TypeConfig[location.type]?.color || '#888888';
};


export function convertLocationsToGeoJSON(locations: Location[]): any {
    if (!locations || locations.length === 0) return [];

    // Filter out locations without valid lat/lng and where id is duplicated
    const filteredLocations = locations.filter(
        (loc, index, self) => 
            loc.lat !== undefined && loc.lng !== undefined && loc.lat !== null && loc.lng !== null && 
            index === self.findIndex((t) => (t.id === loc.id))
    );

    // Convert filtered locations to GeoJSON format
    const geoJSON = {
        type: "FeatureCollection",
        features: filteredLocations.map(loc => ({
            type: "Feature",
            properties: {
                id: loc.id,
                name: loc.name,
                description: loc.description,
                type: loc.type,
                last_audit: loc.last_audit,
                watching: loc.watching,
                created_at: loc.created_at,
                created_by: loc.created_by,
                temp_id: loc.temp_id,
                attributes: loc.attributes,
                color: getTypeColor(loc),
                loc_count: loc.num_locations,
                attrs: loc.attrs
            },
            geometry: {
                type: "Point",
                coordinates: [loc.lng, loc.lat]
            }
        }))
    };

    return geoJSON;
}

export function convertPolygonsToGeoJSON(locations: Location[]): any {
    if (!locations || locations.length === 0) return [];
    
    // Filter out locations without valid polygon and duplicate ids
    const filteredPolygonLocations = locations.filter(
        (loc, index, self) => 
            loc.polygon !== undefined 
            && loc.polygon !== null 
            && loc.polygon !== '[]'
            && index === self.findIndex(    (t) => (t.id === loc.id))
    );

    // Convert filtered locations to GeoJSON format
    const geoJSON = filteredPolygonLocations.map(loc => ({
        type: "Feature",
        properties: {
            id: loc.id,
            name: loc.name,
            description: loc.description,
            type: loc.type,
            last_audit: loc.last_audit,
            watching: loc.watching,
            created_at: loc.created_at,
            created_by: loc.created_by,
            temp_id: loc.temp_id,
            attributes: loc.attributes,
            color: getTypeColor(loc),
            loc_count: loc.num_locations,
            attrs: loc.attrs
        },
        geometry: {
            type: "Polygon",
            coordinates: JSON.parse(loc.polygon)
        }
    }));

    return geoJSON;
}

// given an array of arrays with lng & lat coordinates, return the center of the polygon
export function getPolygonCenter(polygon: number[][], top: Boolean): Types.LatLngLiteral {
    top = top || false;
    const coords = { lat: [], lng: [] };

    polygon.forEach((coord) => {
        coords.lat.push(coord[1]);
        coords.lng.push(coord[0]);
    });

    const lngSum = coords.lng.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const latSum = coords.lat.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return {
        lat: top ? Math.max(...coords.lat) : latSum / polygon.length,
        lng: lngSum / polygon.length 
    };
}

export const doFlyToProjectLocations = (locations: Types.Location[], ref: any) => {
    if (locations.length === 0) return;

    // get an array of all the coordinates
    const coords = extractCoordinates(locations);
    const centerLocs = calculateCenterPoint(coords);
    const bounds = calculateBoundingBox(coords);
    const zoom = calculateZoomLevel(bounds, window.innerWidth, window.innerHeight);

    if (!centerLocs) return;

    // mapCenter.lng, mapCenter.lat
    ref.flyTo({ center: [centerLocs.lng, centerLocs.lat], zoom: zoom-0.5 });
};

const extractCoordinates = (locations) => {
    const coords = [];

    locations.forEach(location => {
        if (location.lat && location.lng) {
            coords.push({ lat: location.lat, lng: location.lng });
        } 
        
        if (location.polygon) {
            const polygon = JSON.parse(location.polygon);

            polygon[0].forEach(polygonVertex => {
                coords.push({ lat: polygonVertex[1], lng: polygonVertex[0] });
            });
        }
    });

    return coords;
}

// get the center point of an array of coordinates
const calculateCenterPoint = (coords) => {
    const totalCoords = coords.length;
    const sumLatLng = coords.reduce(
        (acc, coord) => {
            acc.lat += coord.lat;
            acc.lng += coord.lng;
            return acc;
        },
        { lat: 0, lng: 0 }
    );

    return {
        lat: sumLatLng.lat / totalCoords,
        lng: sumLatLng.lng / totalCoords,
    };
}

function calculateBoundingBox(coords) {
    const latitudes = coords.map(coord => coord.lat);
    const longitudes = coords.map(coord => coord.lng);
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    return {
        minLat,
        maxLat,
        minLng,
        maxLng,
    };
}

function calculateZoomLevel(bbox, mapWidth, mapHeight) {
    const { minLng, minLat, maxLng, maxLat } = bbox;

    const viewport = fitBounds({
        width: mapWidth,
        height: mapHeight,
        bounds: [
            [minLng, minLat], // southwestern corner of the bounding box
            [maxLng, maxLat], // northeastern corner of the bounding box
        ],
    });

    return viewport.zoom;
}

// takes an array of coordinates and returns the convex hull
export const getConvexHull = (coords) => {
    // Convert the array of coordinates to a GeoJSON FeatureCollection
    const points = turf.featureCollection(
        coords.map(([lng, lat]) => turf.point([lng, lat]))
    );

    // Calculate the convex hull (which will be a polygon enclosing all points)
    const hull = turf.convex(points);

    // Extract the coordinates of the convex hull
    return hull ? hull.geometry.coordinates : [];
};